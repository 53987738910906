





































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Concejo extends Vue {
  private win = window;
  private base = window.location;
  private menu = "";
  private menuLateral: any = [];
  private nombreWeb: any = "";
  private listaConcejales = [
    {
      tipo: "Concejala",
      profesion: "Antropóloga sociocultural",
      universidad: "Universidad de Concepción",
      ocupacion: "",
      nombre: "Jaritza Romina Rivadeneira Muena",
      email: "jrmconcejalajf@gmail.com",
      cargo: "Preside las Comisiones de Cultura, Medio Ambiente, Relaciones Exteriores y Zonas Extremas.",
      foto:
        "https://docs.munijf.cl/web/media/imagenes/2025/02/thumbnail-jaritza-rivadeneira-muena.png"
    },
    {
      tipo: "Concejal",
      profesion: "Profesor de Historia y Geografía",
      universidad: "Universidad de Playa Ancha",
      ocupacion: "",
      nombre: "Juan Carlos Manuel Órdenes Hills",
      email: "jcohills@gmail.com",
      cargo: "Preside las Comisiones de Selkirk y Seguridad Pública.",
      foto:
        "https://docs.munijf.cl/web/media/imagenes/2025/02/thumbnail-juan-carlos-o-rdenes-hills.png"
    },
    {
      tipo: "Concejala",
      profesion: "",
      universidad: "",
      ocupacion: "Concejala",
      nombre: "Elizabeth Mariana Celedon De Rodt ",
      email: "lishairc@gmail.com",
      cargo: "Preside las Comisiones de Finanzas, Control y Emergencias.",
      foto:
        "https://docs.munijf.cl/web/media/imagenes/2025/02/thumbnail-elizabeth-celedo-n-de-rodt.png"
    },
    {
      tipo: "Concejal",
      profesion: "Profesor de Historia y Geografía",
      universidad: "Universidad de Playa Ancha",
      ocupacion: "",
      nombre: "Gregory Ignacio Paredes Arredondo",
      email: "grego.ignap@gmail.com",
      cargo: "Preside las Comisiones de Social, Inclusión, Deportes, Salud y Seguridad Pública.",
      foto:
        "https://docs.munijf.cl/web/media/imagenes/2025/02/thumbnail-gregory-paredes-arredondo.png"
    },
    {
      tipo: "Concejal",
      profesion: "Técnico en Administración de Empresas",
      universidad: "Universidad del Mar",
      ocupacion: "",
      nombre: "Andrés Sebastián Salas Burgos",
      email: "concejal.andressalas@gmail.com",
      cargo: "Preside las Comisiones de Asuntos Marítimos, Educación y Vivienda.",
      foto:
        "https://docs.munijf.cl/web/media/imagenes/2025/02/thumbnail-andre-s-salas-burgos.png"
    },
    {
      tipo: "Concejal",
      profesion: "",
      universidad: "",
      ocupacion: "Concejal",
      nombre: "Francisco Javier Balbontín González",
      email: "franciscobalbontin82@gmail.com",
      cargo: "Preside las Comisiones de Turismo, Emergencia y Urbanismo.",
      foto:
        "https://docs.munijf.cl/web/media/imagenes/2025/02/thumbnail-francisco-balbonti-n-gonza-lez.png"
    }
  ];
  private actasSesion: Array<any> = [];

  private beforeMount() { }
  private mounted() {
    document.title =
      "Concejo Municipal - Ilustre Municipalidad de Juan Fernández";
    this.setMenu();
    this.getActas();
  }

  private getActas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("actas/concejo")
      .then(res => {
        let base = process.env.VUE_APP_BASE_URL;
        this.actasSesion = res.data;
        for (let i = 0; i < this.actasSesion.length; i++) {
          for (let j = 0; j < this.actasSesion[i].actas_sesion.length; j++) {
            this.actasSesion[i].actas_sesion[j].url_archivo =
              base + this.actasSesion[i].actas_sesion[j].url_archivo;
          }
          for (let j = 0; j < this.actasSesion[i].actas_comision.length; j++) {
            this.actasSesion[i].actas_comision[j].url_archivo =
              base + this.actasSesion[i].actas_comision[j].url_archivo;
          }
          for (
            let j = 0;
            j < this.actasSesion[i].actas_extraordinarias.length;
            j++
          ) {
            this.actasSesion[i].actas_extraordinarias[j].url_archivo =
              base + this.actasSesion[i].actas_extraordinarias[j].url_archivo;
          }
          for (let j = 0; j < this.actasSesion[i].documentos.length; j++) {
            this.actasSesion[i].documentos[j].url_archivo =
              base + this.actasSesion[i].documentos[j].url_archivo;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.actasSesion = [];
      });
  }

  private descargarArchivo(url: string) {
    this.win.open(url, "_blank");
    return;
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private async setMenu() {
    const runtimeConfig1 = await fetch("/menu.json");
    this.menu = await runtimeConfig1.json();
    for (let i = 0; i < this.menu.length; i++) {
      this.getSubMenu(this.menu[i], "concejo-municipal", 1);
    }
  }

  private getSubMenu(element: any, nombre_web: string, profundidad) {
    if (element.nombre_web == nombre_web) {
      return true;
    } else {
      if (element.sub_menu && element.sub_menu.length > 0) {
        for (let j = 0; j < element.sub_menu.length; j++) {
          if (
            this.getSubMenu(element.sub_menu[j], nombre_web, profundidad + 1)
          ) {
            if (profundidad == 2) {
              return true;
            } else {
              this.menuLateral = element.sub_menu;
              this.checkSubMenus();
              return false;
            }
          }
        }
      }
      return false;
    }
  }

  private selectedStyle(item: any) {
    if ("concejo-municipal" == item.nombre_web) {
      item.expand = true;
      return " background-color: #4E7DBB !important; color: #f1f1f1 !important;";
    } else {
      let childSelected = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        for (let i = 0; i < item.sub_menu.length; i++) {
          if ("concejo-municipal" == item.sub_menu[i].nombre_web) {
            childSelected = true;
          }
        }
        if (childSelected) {
          item.expand = true;
          return " background-color: #4E7DBB !important; color: #f1f1f1 !important;";
        }
      }
      return "";
    }
  }

  private checkSubMenus() {
    for (let i = 0; i < this.menuLateral.length; i++) {
      if (this.menuLateral[i].sub_menu.length > 0) {
        if (
          this.menuLateral[i].tipo == 2 &&
          "concejo" == this.menuLateral[i].nombre_web
        ) {
          this.menuLateral[i].expand = true;
        }
      } else {
        this.menuLateral[i].expand = false;
      }
    }
  }

  private toLink(item: any, blank: any) {
    if (item && item != "") {
      if (blank == 1) {
        window.open(item, "_blank");
      } else {
        window.open(item, "_self");
      }
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }

  private sendMail(correo: string) {
    window.open("mailto:" + correo);
  }
}
